import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ClearState } from '../../store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  constructor(private store: Store) {}

  newTab() {
    let tabCount = parseInt(localStorage.getItem('tabCount'));
    tabCount = Number.isNaN(tabCount) ? 1 : ++tabCount;
    localStorage.setItem('tabCount', tabCount.toString());
  }

  closeTab() {
    let tabCount = parseInt(localStorage.getItem('tabCount'));
    --tabCount;
    if (tabCount <= 0) {
      this.store.dispatch(ClearState);
    } else {
      localStorage.setItem('tabCount', tabCount.toString());
    }
  }
}
