import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  ClearRemainingAttempt,
  ClearState,
  GetUserById,
  Login,
  Logout,
  SaveUserDetail,
} from './auth.actions';
import {
  AuthResponse,
  AuthStateModel,
  UserDetail,
} from './auth.model';

import { tokenDecoded } from 'src/app/shared/utils/utils';
import { Observable } from 'rxjs';
import { DropdownService } from '../../shared/service/dropdown.service';
import { NavbarService } from '../../core/components/navbar/navbar.service';
import { TabService } from '../../shared/service/tab.service';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    refresh: null,
    access: null,
    username: null,
    first_name: null,
    last_name: null,
    photo: null,
    id: null,
    person_id: null,
    signature: null,
    departments: null,
    job_position: null,
    is_set_password: null,
    is_password_valid: null,
    role: null,
    otp_type: null,
    phone_number: null,
    email: null,
    is_custom: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  constructor(
    private authService: AuthenticationService,
    private dropdownService: DropdownService,
    private router: Router,
    private spinner: SpinnerService,
    private alert: AlertService,
    private translate: TranslateService,
    private store: Store,
    private zone: NgZone,
    private navbar: NavbarService,
    private activated: ActivatedRoute,
    private tabService: TabService,
  ) {}

  @Selector()
  static token(state: AuthStateModel): AuthStateModel {
    return state;
  }

  setUserDataToStorage(result: AuthResponse): void {
    localStorage.setItem('username', result.username);
    localStorage.setItem('photoUrl', result.photo || '');
    localStorage.setItem('currentUser', result.access);
    localStorage.setItem('pid', result.person_id + '');
    localStorage.setItem('refresh', result.refresh.replace(`"`, ''));
    this.updateLocalStorage(result);
  }

  updateLocalStorage(result: AuthResponse | UserDetail): void {
    localStorage.setItem(
      'is_set_password',
      result.is_set_password.toString(),
    );
    localStorage.setItem(
      'is_password_valid',
      result.is_password_valid.toString(),
    );
  }

  @Action(Login)
  login({ setState }: StateContext<AuthStateModel>, { payload }) {
    this.spinner.show();
    return this.authService
      .login(payload.username, payload.password)
      .pipe(
        tap((result) => {
          this.setUserDataToStorage(result);
          result.role = result.role.replace(
            /[&\/\\#,+()[\]$~%.'":*?<>{}]/g,
            '',
          );
          this.store.dispatch(GetUserById);
          setState({
            ...result,
          });
        }),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(
        (response) => {
          if (
            !response.is_set_password ||
            !response.is_password_valid
          ) {
            this.zone.run(() => {
              this.router.navigate(['/reset-password']);
              setTimeout(() => {
                location.reload();
              }, 500);
            });
            return;
          }
          if (
            response.role !== 'General' &&
            response.role !== 'Contract Management'
          ) {
            this.zone.runOutsideAngular(() => {
              this.tabService.newTab();
              this.zone.run(() => {
                this.router.navigate(['/users']);
              });
            });
          } else {
            this.zone.runOutsideAngular(() => {
              this.tabService.newTab();
              this.activated.queryParams.subscribe((queryParams) => {
                if (queryParams.returnUrlEvidence) {
                  const decodedQueryString = atob(
                    queryParams.returnUrlEvidence,
                  );
                  const queryString = JSON.parse(decodedQueryString);
                  this.zone.run(() => {
                    this.router.navigate(
                      ['/contract/preview/' + queryString.id],
                      {
                        queryParams: { tab: queryString.tab },
                      },
                    );
                  });
                } else if (this.router.url.includes('/login')) {
                  // route to HomeComponent
                  this.zone.run(() => {
                    this.router.navigateByUrl(
                      decodeURIComponent(
                        this.activated.snapshot.queryParams
                          ?.returnUrl || '/',
                      ),
                    );
                  });
                }
              });
            });
          }
        },
        (error) => {
          if (error?.error?.detail == 'disabled_account') {
            setState({
              remaining_attempts: -999,
            });
          } else if (error?.error?.remaining_attempts) {
            setState({
              remaining_attempts: error?.error?.remaining_attempts,
            });
          } else {
            /// error response
            this.alert.error(
              error?.error?.detail ||
                this.translate.instant('ERROR.CONTACT-DEV'),
            );
          }
        },
      );
  }

  @Action(ClearRemainingAttempt)
  clearRemainingAttempts({ setState }: StateContext<AuthStateModel>) {
    setState({
      remaining_attempts: null,
    });
  }

  @Action(Logout)
  logout({ setState }: StateContext<AuthStateModel>): AuthStateModel {
    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    localStorage.clear();
    sessionStorage.clear();
    this.navbar.setActiveSidebar(false);
    return setState(null);
  }

  @Action(ClearState)
  clearState({
    setState,
  }: StateContext<AuthStateModel>): AuthStateModel {
    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    localStorage.clear();
    return setState(null);
  }

  @Action(GetUserById)
  getUser({
    setState,
    getState,
  }: StateContext<AuthStateModel>):
    | Observable<UserDetail>
    | Promise<boolean> {
    const state = getState();
    const pid = state?.person_id
      ? state.person_id
      : localStorage.getItem('pid');
    return this.authService
      .getPersonDetail(pid ? +pid : tokenDecoded().user_id)
      .pipe(
        tap((user) => {
          setState({
            refresh: localStorage.getItem('currentUser'),
            access: localStorage.getItem('currentUser'),
            username: user.user.username,
            first_name: user.user.first_name,
            last_name: user.user.last_name,
            photo: user.photo,
            id: user.user.id,
            is_set_password: user.is_set_password,
            is_password_valid: user.is_password_valid,
            person_id: user.id,
            departments: user.departments_id,
            job_position: user.job_position,
            role: user.role.name,
            signature: user.signature,
            otp_type: user.otp_type,
            phone_number: user.phone_number,
            email: user.user.email,
            can_view_all_memo: user.can_view_all_memo,
            otp_m26_m28: user.otp_m26_m28,
          });
          this.updateLocalStorage(user);
        }),
      );
  }

  @Action(SaveUserDetail)
  saveItem(
    { setState, getState }: StateContext<AuthStateModel>,
    { value, name }: { value: any; name: string },
  ): void {
    const state = getState();

    setState({
      ...state,
      [name]: value,
    });
  }
}
